.formControl{width: 100%;}
.formControl .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]{padding-top:1px; padding-bottom:1px; height:40px;}
 .formControl .MuiInputLabel-formControl{top:-8px;}
 .formControl .MuiInputBase-root{height:40px;}
 .formControl .MuiOutlinedInput-multiline{min-height:80px;}
 .formControl .MuiOutlinedInput-input{  color:#535456;}
 /* .formControl .MuiOutlinedInput-input{padding:8.5px 14px 10.5px 14px; font-size:16px; color:#535456;} */
 .formControl .MuiInputLabel-outlined.MuiInputLabel-shrink{top:-2px;}
 .formControl .MuiAutocomplete-inputRoot .MuiAutocomplete-input{ color:#535456;}

 .tox-tinymce{
    border-radius: 5px;
 } 
 